@font-face {
  font-family: "Myriad Pro Condensed"; 
  src: url('./fonts/MyriadPro-Cond.woff');
}

@font-face {
  font-family: "Myriad Pro Light Condensed"; 
  src: url('./fonts/MyriadPro-LightCond.woff');
}

@font-face {
  font-family: "Myriad Pro Light"; 
  src: url('./fonts/MyriadPro-Light.woff');
}


html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: black;
  background-color: #ddd;
  min-height: 100%;
}

.center {
  text-align: center;
}

a {
  color: black;
  text-decoration: underline;
}

h1,
h2,
h3 {
  font-weight: normal;
  font-family: "Myriad Pro Light Condensed", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 20px;
  line-height: 1.3em;
  color: #414042;
  margin-top: 0;
}

h1 a,
h2 a,
h3 a {
  text-decoration: none;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 1.75em;
  margin-bottom: 4px;
  margin-top: 0px;
  padding-top: 0px;
}

h3 {
  font-size: 1.5em;
}

img.fullwidth {
  min-width: 100%;
  max-width: 100%;
}

ul {
  padding-left: 24px;
}

p,
div,
.wrapper li,
input.email,
input[type=text],
input[type=email],
input[type=submit],
textarea,
div.submit input {
  font-family: "Myriad Pro Light", sans-serif;
  font-variant-numeric: oldstyle-nums;
  -moz-font-feature-settings: "onum";
  -webkit-font-feature-settings: "onum";
  font-feature-settings: "onum";
    font-size: 1em;
  line-height: 1.6em;
  font-weight: 100;
}

.vat-number, .phone {
  font-variant-numeric: normal;
  -moz-font-feature-settings: "ordn";
  -webkit-font-feature-settings: "ordn";
}

input, textarea {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  -webkit-appearance: none;
}

p {
  font-size: 1em;
}

/* .react-photo-gallery--gallery img {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
} */

@media all and (max-width: 640px) {
  h1 {
    font-size: 2.0em;
  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1em;
  }
}
